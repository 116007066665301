import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import axiosIns from "@/libs/axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useSuppliersInfo() {
  // Use toast
  const toast = useToast();

  const suppliersInfo = ref({})
  const fetchSuppliersInfo = (ctx, callback) => {
    store
      .dispatch("supplier-info/fetchSuppliersInfo")
      .then((response) => {
        const { supplier } = response.data;
        suppliersInfo.value = supplier
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取貸款清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }; 


  onMounted(() => {
    fetchSuppliersInfo();
  });

  return {
    ability,
    fetchSuppliersInfo,
    suppliersInfo,
  };
}
