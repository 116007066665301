<template>
  <div class="">
    <b-card class="">
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="d-flex mb-1 mb-sm-0 align-items-center">
          <h4
            class="d-flex align-items-center font-weight-bolder ml-1 text-nowrap mb-0"
          >
            <feather-icon
              class="mr-1"
              icon="ClipboardIcon"
              size="18"
            ></feather-icon>
            做客數據
          </h4>
        </div>
      </div>
        <div style="min-height:80vh; display:grid; place-items:center">
          <b-row class="justify-content-center">
              <div class="info-item mr-2">
                <b class="">搜尋：</b
                ><span>
                  {{ suppliersInfo.search_records_count }}次
                </span>
              </div>
              <div class="info-item mr-2">
                <b class="">拒絕紀錄：</b
                ><span>{{
                      suppliersInfo.reject_records_count
                }}次</span>
              </div>
              <div class="info-item mr-2">
                <b class="">財務狀況：</b
                ><span>{{ suppliersInfo.client_loans_count }}次</span>
              </div>
              <div class="info-item mr-2">
                <b class="">帳務紀錄：</b
                ><span>{{ suppliersInfo.account_records_count }}次</span>
              </div>
              <div class="info-item mr-2">
                <b class="">無上傳記錄：</b
                ><span>{{ calNoUpload }}次</span>
              </div>
              <div class="info-item">
                <b class="">上傳率：</b
                ><span>{{ calUploadRate}}%</span>
              </div>
          </b-row>
        </div>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import useSuppliersInfo from "./useSuppliersInfo";
import supplierStoreModule from "../supplierStoreModule";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {};
  },
  methods: {},
  setup() {
    const USER_APP_STORE_MODULE_NAME = "supplier-info";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, supplierStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const calUploadRate = computed(() => {
      const cal =  Number(suppliersInfo.value.valid_client_count) / Number(suppliersInfo.value.account_records_count)
      cal > 1 ? 1 : cal
      return cal * 100
    })

    const calNoUpload = computed(() => {
      const cal = Number(suppliersInfo.value.client_count) - Number(suppliersInfo.value.valid_client_count) + Number(suppliersInfo.value.contract_count)
      return cal > 0 ? cal : 0
    })

    const { ability, fetchSuppliersInfo, suppliersInfo } = useSuppliersInfo();

    return {
      ability,
      fetchSuppliersInfo,
      suppliersInfo,
      calUploadRate,
      calNoUpload
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.info-item{
  font-size:1.2rem;
  b{
    color: #676767;
  }
}

// @media screen and (max-width: 768px) {

// }
// @media screen and (max-width: 575px) {

// }
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";


</style>
    